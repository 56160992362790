interface ILockrOptionType {
    readonly title: 'lockrMail' | 'lockrScan';
    readonly subTitle: string;
    readonly onPress: () => void;
}

interface ILockrUpcomingFeatureType {
    readonly title: 'lockrPay' | 'lockrText';
}

function LockrOption(props: ILockrOptionType) {
    return (
        <div className={'option-parent'}>
            <div
                className={props.title === 'lockrMail' ? 'option-logo-container-lockrmail' : 'option-logo-container-lockrscan'}>
                <img className={'options-logo'} alt={'option-logo'}
                     src={props.title === 'lockrMail' ? require('./Assets/mail_logo.png') : require('./Assets/scan_logo.png')}/>
            </div>
            <div className={'options-body-container'}>
                <span className={'options-title-container'}>{props.title}</span>
                <span className={'options-subtitle'}>{props.subTitle}</span>
                <div onClick={props.onPress} className={'options-button'}>{`Visit ${props.title}`}</div>
            </div>
        </div>
    )
}


function UpComingFeature(props: ILockrUpcomingFeatureType) {
    return (
        <div className={'upcoming-feature-parent'}>
            <div
                className={props.title === 'lockrPay' ? 'upcoming-feature-logo-container-lockrpay' : 'upcoming-feature-logo-container-lockrtext'}>
                <img className={'upcoming-feature-img'} alt='feature-logo'
                     src={props.title === 'lockrPay' ? require('./Assets/money_wallet.png') : require('./Assets/phone.png')}/>
            </div>
            <div className={'upcoming-feature-title-container'}>
                <span className={'upcoming-feature-coming-soon'}>Coming soon</span>
                <span className={'upcoming-feature-title'}>{props.title}</span>
            </div>
        </div>
    )
}

function LockrFooter() {
    return (
        <div className={'footer-container'}>
            <div className={'lock-logo-container'}>
                <img alt={'lock-logo'} className={'lock-logo'} src={require('./Assets/lock.png')}/>
            </div>
            <span onClick={() => {
                window.open('https://lockrmail.com/privacy-policy/', '_blank');
            }} className={'privacy-policy-text'}>Privacy Policy</span>
            <span className={'copyright-text'}>© {new Date().getFullYear()} lockr</span>
        </div>
    )
}

export function MyLockrPage() {
    return (
        <>
            <div className='page-main-container'>
                <img className={'dots-img'} alt={'dots'} src={require('./Assets/dots.png')}/>
                <div className={'lockr-logo-container'}>
                    <img className={'lockr-logo'} alt={'logo'} src={require('./Assets/logo.png')}/>
                </div>
                <div className={'content-container'}>
                    <span className={'welcome-title'}>Welcome to My lockr.</span>
                    <span className={'welcome-message'}>A suite of consumer products designed to give you control over your identity, consent and data.</span>
                </div>
                <div className={'options-container'}>
                    <LockrOption onPress={() => {
                        window.open('https://www.lockrmail.com', '_blank');
                    }} title={'lockrMail'} subTitle={'A new email address, not a new inbox.'}/>
                    <div className={'width-40'}/>
                    <LockrOption onPress={() => {
                        window.open('https://www.lockrscan.com', '_blank');
                    }} title={'lockrScan'} subTitle={'Clean up your Gmail inbox.'}/>
                </div>
                <div className={'chrome-extension-container'}>
                    <img className={'chrome-extension-background-img'}
                         src={require('./Assets/chrome_extension_background.png')}/>
                    <div className={'chrome-extension-title-container'}>
                        <span className={'chrome-extension-title'}>lockr Chrome extension</span>
                        <span className={'chrome-extension-subtitle'}>Enhance your lockrMail experience. </span>
                    </div>
                    <div onClick={() => {
                        window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank');
                    }} className={'chrome-extension-install-button'}>Install Now
                    </div>
                </div>
                <div className={'upcoming-feature-container'}>
                    <UpComingFeature title={'lockrPay'}/>
                    <div className={'width-40'}/>
                    <UpComingFeature title={'lockrText'}/>
                </div>
            </div>
            <LockrFooter/>
        </>
    )
}
